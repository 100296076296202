exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-changelog-entry-tsx": () => import("./../../../src/templates/changelog-entry.tsx" /* webpackChunkName: "component---src-templates-changelog-entry-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tutorial-tsx": () => import("./../../../src/templates/tutorial.tsx" /* webpackChunkName: "component---src-templates-tutorial-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */)
}

